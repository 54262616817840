import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../../components/layout/Layout.component";
import Author from "../../../components/author/Author.component";
import * as style from "../Project.style.module.scss";

const Abinwestor = () => {
  return (
    <Layout>
      <StaticImage
        src="./images/markus-spiske-1LLh8k2_YFk-unsplash.jpg"
        as="header"
        className={style.hero}
      />
      <main className={style.post}>
        <article className={style.article}>
          <div className={style.date}>Project</div>
          <h1>AB inwestor</h1>
          <h2>Introduction</h2>
          <p>
            I did not design this graphic layer myself. I built the entire site
            and added a logical layer. The page changed after putting it on the
            server. The client asked for adding new functionalities. Tracking
            customers which investment they were most eager to see. Generating
            PDF files from the table of available apartments. The content can be
            edited by the client. Partial form filling using redirects. That's
            all for now, but the site is still being dynamically changed.
          </p>
          <h2>Sketch</h2>
          <StaticImage src="./images/abinwestor.png" as="div" />
          <h2>Result</h2>
          <StaticImage src="./images/screenshot_abinwestor.png" as="div" />
        </article>
        <aside>
          <Author />
        </aside>
      </main>
    </Layout>
  );
};
export default Abinwestor;

export function Head() {
  return <title>AB inwestor</title>;
}
